<template>
  <div class="abnormalDetail">
    <Navlid :list="navList"></Navlid>
    <el-form ref="form" label-width="100px" style="margintop: 50px" v-if="form">
      <el-form-item label="上报人:" class="formDetail">
        <el-col :span="12">
          <span class="item">{{ form.worker_name }}</span>
        </el-col>
      </el-form-item>
      <el-form-item label="地点:" class="formDetail">
        <el-col :span="12">
          <span class="item">{{ form.abnormal_address }}</span>
        </el-col>
      </el-form-item>
      <el-form-item label="上报时间:" class="formDetail">
        <el-col :span="12">
          <span class="item">{{ form.create_at }}</span>
        </el-col>
      </el-form-item>
      <el-form-item label="异常问题:" class="formDetail" style="margintop: 50px">
        <el-col :span="12">
          <span class="item">{{ form.abnormal_title }}</span>
        </el-col>
      </el-form-item>
      <el-form-item label="详细描述:" class="formDetail">
        <el-col :span="12">
          <span class="item">{{ form.inspection_abnormal }}</span>
        </el-col>
      </el-form-item>

      <el-form-item label="处理情况:" style="margintop: 50px" class="formDetail">
        <el-col :span="12">
          <span style="color: #02ce80" v-if="form.abnormal_status == 20">已处理</span>
          <span style="color: #e90000" v-else>未处理</span>
        </el-col>
      </el-form-item>
      <el-form-item label="处理人:" class="formDetail" v-if="form.abnormal_status == 20">
        <el-col :span="12">
          <span class="item">{{ form.abnormal_worker_name }}</span>
        </el-col>
      </el-form-item>
      <el-form-item label="现场异常图片:" style="margintop: 50px" class="formDetail">
        <el-col :span="12">
          <div class="imgList">
            <img class="imgBox" v-preview v-img :src="item" v-for="(item, index) in form.abnormal_images" :key="index"
              alt="" />
          </div>
        </el-col>
        <el-col :span="12" class="formDetail" v-if="form.handle_images.length > 0">
          <div class="formBox">
            <span class="name">处理后图片:</span>
            <div class="imgList">
              <img class="imgBox" v-preview :src="item" v-img v-for="(item, index) in form.handle_images" :key="index"
                alt="" />
            </div>
          </div>
        </el-col>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  name: "abnormalDetail",
  data () {
    return {
      navList: [
        {
          title: "巡检情况",
          url: "/polling/abnormal",
        },
        {
          title: "巡检情况详情",
        },
      ],
      form: null,
    };
  },
  created () {
    this.id = this.$route.query.id;
    this.getDetail();
  },
  methods: {
    getDetail () {
      this.$request
        .HttpGet("/inspection_task_log/list", {
          log_id: this.id,
        })
        .then((res) => {
          if (res.data) {
            if (res.data.list[0].handle_images) {
              let images = [];
              if (res.data.list[0].handle_images.indexOf('[') != -1) {
                images = JSON.parse(res.data.list[0].handle_images);
              } else {
                images = res.data.list[0].handle_images.split(',');
              }
              let arr = [];
              images.forEach((item) => {
                arr.push(process.env.VUE_APP_IMG_URL + item);
              });
              res.data.list[0].handle_images = arr;
            }
            if (res.data.list[0].abnormal_images) {
              try {
                if (res.data.list[0].abnormal_images.indexOf('[') != -1) {
                  res.data.list[0].abnormal_images = JSON.parse(res.data.list[0].abnormal_images);
                } else {
                  res.data.list[0].abnormal_images = res.data.list[0].abnormal_images.split(',');
                }
                let arr = [];
                res.data.list[0].abnormal_images.forEach((item) => {
                  arr.push(process.env.VUE_APP_IMG_URL + item);
                });
                res.data.list[0].abnormal_images = arr;
              } catch (err) {
                res.data.list[0].abnormal_images = [];
              }
            }

            this.form = res.data.list[0];
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.imgList {
  display: flex;

  .imgBox {
    width: 110px;
    height: 110px;
    margin-right: 15px;
    margin-bottom: 15px;
  }
}

.formBox {
  display: flex;

  .name {
    width: 100px;
  }
}

.item {
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

/deep/ .el-form-item__label {
  color: #999999;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
}
</style>
